import { combineReducers } from "redux";
import { authActions } from "../actions/auth";
import { organizationsActions } from "../actions/organizations";
import { partnersActions } from "../actions/partners";
import { rolesActions } from "../actions/roles";
import { usersActions } from "../actions/users";
import { auditActions } from "../actions/audit";
import { userProfileActions } from "../actions/userProfile";
import { releasesActions } from "../actions/releases";
import { invoicesActions } from "../actions/invoices";
import {
  UserType,
  UserProfileType,
  OrganizationType,
  RoleType,
  ProductType,
  SubscriptionType,
  UserSubscriptionType,
  RolePermissionType,
  UserBulkUploadType,
  AuditLogType,
  TableType,
  ReleaseType,
  LicenseType,
  CustomError,
  ResellerType,
  ContractType,
  InvoiceType,
  SubscriptionHistoryType,
  PartnerType,
  ReferralType,
  PackageType,
  CountryType
} from "../services/interfaces";
import autnhiveReducers, { AutnhiveReducers } from "./autnhive";
import orgLevelReducers, { OrgLevelReducers } from "./orgLevel";
import resellerReducers, { ResellerReducers } from "./resellers";
import { licenseActions } from "../actions/licenses";
import moment from "moment";
import { securityNotificationsActions } from "../actions/securityNotifications";
import { materialMasterDataActions } from "../actions/materialMasterData";
import { stat } from "fs";
import { samiReportTypeBillingActions } from "../actions/samiReportTypeBilling";

export interface GlobalReducer {
  sessionLoading: boolean;
  loggedIn: boolean;
  userProfile?: UserProfileType;
  countries?: Array<CountryType>
}

export interface LoginPageReducer {
  request_token: string | null;
  salt: string | null;
  request_new_password?: boolean;
  request_to_accept_terms?: boolean;
  request_to_accept_privacy_policy?: boolean;
  error?: CustomError;
  loading?: boolean;
  success?: string;
}

export interface ResetPasswordPageReducer {
  request_token: string | null;
  salt: string | null;
  error?: CustomError;
  loading?: boolean;
  success?: string;
}

export interface UpdateUserProfileReducer {
  loading: boolean;
  error?: CustomError;
  success?: string;
}

export interface OrganizationsPageReducer {
  organizations: Array<OrganizationType>;
  contracts: Array<ContractType>;
  referrals: Array<ReferralType>;
  resellers: Array<ResellerType>;
  products: Array<ProductType>;
  packages: Array<PackageType>;
  count: number;
  searchKeyword: string;
  page: number;
  loading: boolean;
}

export interface UpdateOrganizationReducer {
  loading: boolean;
  success?: string;
  error?: CustomError;
}

export interface UsersPageReducer {
  users: Array<UserType>;
  organizations: Array<OrganizationType>;
  count: number;
  page: number;
  loading: boolean;
  organization_id?: string;
  searchKeyword: string;
}

export interface RolesPageReducer {
  roles: Array<RoleType>;
  searchResults: Array<RoleType>;
  organizations: Array<OrganizationType>;
  resellers: Array<ResellerType>;
  loading: boolean;
  success?: string;
  error?: CustomError;
  count: number;
  page: number;
  keyword?: string;
  searchType: "all-roles" | "organization" | "role-name";
}

export interface CreateUserReducer {
  loading: boolean;
  error?: CustomError;
  roles: Array<RoleType>;
}

export interface UserBulkUploadReducer {
  loading: boolean;
  error?: CustomError;
  uploadResult?: [UserBulkUploadType];
}

export interface UpdateUserReducer {
  loading: boolean;
  error?: CustomError;
  roles: Array<RoleType>;
}

export interface CreateRoleReducer {
  loading: boolean;
  success?: string;
  error?: CustomError;
}

export interface UpdateRoleReducer {
  loading: boolean;
  success?: string;
  error?: CustomError;
}

export interface PartnersPageReducer {
  partners: Array<PartnerType>;
  count: number;
  page: number;
  loading: boolean;
}

export interface CreatePartnerReducer {
  loading: boolean;
  error?: CustomError;
}

export interface UpdatePartnerReducer {
  loading: boolean;
  error?: CustomError;
}

export interface ProductSubscriptionsReducer {
  productSubscriptions: Array<SubscriptionType>;
  loading: boolean;
  success?: string;
  count: number;
  page: number;
  error?: CustomError;
}

export interface SubscriptionsHistoryReducer {
  subscriptionsHistory: Array<SubscriptionHistoryType>;
  subscription_id?: string;
  start_date?: Date;
  end_date?: Date;
  loading: boolean;
  count: number;
  page: number;
}

export interface UserProductSubscriptionsReducer {
  subscriptions: Array<UserSubscriptionType>;
  loading: boolean;
  success?: string;
  error?: CustomError;
}
export interface UpdateProductSubscriptionReducer {
  loading: boolean;
  success?: string;
  error?: CustomError;
}

export interface RolePermissionsReducer {
  loading: boolean;
  permissions: Array<RolePermissionType>;
}

export interface LicensesPageReducer {
  loading: boolean;
  count: number;
  page: number;
  licenses: Array<LicenseType>;
  organizations: Array<OrganizationType>;
  products: Array<ProductType>;
}

export interface AuditPageReducer {
  logs: Array<AuditLogType>;
  tables: Array<TableType>;
  selectedTable: string;
  count: number;
  page: number;
  loading: boolean;
  organization_id: string;
  organizations: Array<OrganizationType>;
  action: string;
}

export interface ReleasesReducer {
  loading: boolean;
  releases: Array<ReleaseType>;
  downloadProgress: boolean;
  page: number;
  count: number;
}

export interface InvoicesReducer {
  loading: boolean;
  invoices: Array<InvoiceType>;
  page: number;
  count: number;
  startDate: Date;
  endDate: Date;
  keyword: string;
  status: string;
}

export interface SecurityNotificationsPageReducer {
  securityNotificationsList: Array<SecurityNotificationsType>;
  count: number;
  page: number;
  loading: boolean;
}

export interface SecurityNotificationsType {
  slug: string;
  organization_slug : string;
  organization_name:string;
  file_name:string;
  business_vertical : string;
  feed_title : string;
  feed : string;
  is_pinned : boolean;
  date :string;
  created_at : string;
  updated_at : string;
}

export interface MaterialMasterDataReducer {
  materialMasterDataList: Array<MaterialMasterData>;
  count: number;
  loading: boolean,
}

export interface MaterialMasterData {
  slug: string;
  name : string;
  code : string;
  report_type : string;
  category_slug : string;
  sub_vulnerability_name : string;
  sub_vulnerability_internal_name : string;
  sector_slug : string;
  cap_range: string;
  frequency : string;
  low_loss : string;
  high_loss : string;
  cost : string;
  material_risk_categories : MaterialRiskCategoriesAndSectors;
  material_risk_sectors : MaterialRiskCategoriesAndSectors;
  is_active : boolean;
  created_at : string;
  updated_at : string;
}

export interface MaterialRiskCategoriesAndSectors {
  slug: string;
  name : string;
  code : string;
  is_active : boolean;
  created_at : string;
  updated_at : string;
}

export interface SamiReportTypeBillingReducer {
  reportTypeBillingList: any;
  loading: boolean
}

export interface SamiReportTypeBillingData {
  id: string;
  report_type: string;
  source_type: string;
  ctem_cost: string;
  additional_cost?: string;
  cost_ctem?: Array<{value: number, currency: string}>
  cost_additional?: Array<{value: number, currency: string}>
}

export interface Reducers {
  globe: GlobalReducer;
  loginPage: LoginPageReducer;
  resetPassword: ResetPasswordPageReducer;
  updateUserProfile: UpdateUserProfileReducer;
  organizationsPage: OrganizationsPageReducer;
  updateOrganization: UpdateOrganizationReducer;
  usersPage: UsersPageReducer;
  createUser: CreateUserReducer;
  userBulkUpload: UserBulkUploadReducer;
  updateUser: UpdateUserReducer;
  rolesPage: RolesPageReducer;
  createRole: CreateRoleReducer;
  updateRole: UpdateRoleReducer;
  rolePermissions: RolePermissionsReducer;
  licensesPage: LicensesPageReducer;
  productSubscriptions: ProductSubscriptionsReducer;
  updateProductSubscription: UpdateProductSubscriptionReducer;
  userProductSubscriptions: UserProductSubscriptionsReducer;
  subscriptionsHistory: SubscriptionsHistoryReducer;
  auditPage: AuditPageReducer;
  releases: ReleasesReducer;
  orgLevel: OrgLevelReducers;
  autnhive: AutnhiveReducers;
  reseller: ResellerReducers;
  invoices: InvoicesReducer;
  partnersPage: PartnersPageReducer;
  createPartner: CreatePartnerReducer;
  updatePartner: UpdatePartnerReducer;
  securityNotificationsPartner: SecurityNotificationsPageReducer;
  materialMasterDataPartner:MaterialMasterDataReducer;
  samiReportTypeBilling: SamiReportTypeBillingReducer;
}

function globalReducer(
  prevState: GlobalReducer = { loggedIn: false, sessionLoading: true },
  { type, payload }: { type: string; payload: UserProfileType | UserType | Array<CountryType> }
): GlobalReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case authActions.initSession:
      state.sessionLoading = true;
      break;
    case authActions.initSessionSucceeded:
      state.loggedIn = true;
      state.sessionLoading = false;
      state.userProfile = payload as UserProfileType;
      break;
    case authActions.initSessionFailed:
      state.loggedIn = false;
      state.sessionLoading = false;
      break;
    case authActions.loginSucceeded:
      state.loggedIn = true;
      state.sessionLoading = false;
      state.userProfile = payload as UserProfileType;
      break;
    case authActions.loginFailed:
      state.loggedIn = false;
      state.sessionLoading = false;
      delete state.userProfile;
      break;
    case authActions.logout:
      state.loggedIn = false;
      delete state.userProfile;
      break;
    case userProfileActions.updateUserProfileSucceeded:
      if (state.userProfile) {
        state.userProfile.user = payload as UserType;
      }
      break;
    case userProfileActions.fetchCountriesSucceeded:
      state.countries = payload as Array<CountryType>;
      break;
    default:
  }
  return state;
}

function loginPageReducer(
  prevState: LoginPageReducer = { request_token: null, salt: null },
  {
    type,
    payload,
  }: {
    type: string;
    payload:
      | UserType
      | CustomError
      | string
      | {
          request_token: string;
          request_new_password: boolean;
          request_to_accept_terms: boolean;
          request_to_accept_privacy_policy: boolean;
        };
  }
): LoginPageReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case authActions.loginOtpWillSubmit:
      state.request_token = null;
      delete state.error;
      state.loading = true;
      break;
    case authActions.loginOtpSucceeded:
      state.loading = false;
      const loginOtpPayload = payload as {
        request_token: string;
        salt: string;
        request_new_password: boolean;
        request_to_accept_terms: boolean;
        request_to_accept_privacy_policy: boolean;
      };
      if ("request_token" in loginOtpPayload) {
        state.request_token = loginOtpPayload.request_token;
      }
      if ("salt" in loginOtpPayload) {
        state.salt = loginOtpPayload.salt;
      }
      if ("request_new_password" in loginOtpPayload) {
        state.request_new_password = loginOtpPayload.request_new_password;
      }
      if ("request_to_accept_terms" in loginOtpPayload) {
        state.request_to_accept_terms = loginOtpPayload.request_to_accept_terms;
      }
      if ("request_to_accept_privacy_policy" in loginOtpPayload) {
        state.request_to_accept_privacy_policy =
          loginOtpPayload.request_to_accept_privacy_policy;
      }
      break;
    case authActions.loginOtpFailed:
      state.loading = false;
      state.error = payload as CustomError;
      break;
    case authActions.loginWillSubmit:
      state.loading = true;
      delete state.error;
      delete state.success;
      break;
    case authActions.loginSucceeded:
      state.loading = false;
      state.request_token = null;
      delete state.error;
      delete state.success;
      break;
    case authActions.loginFailed:
      state.loading = false;
      state.error = payload as CustomError;
      break;
    case authActions.logout:
      state.success = payload as string;
      delete state.error;
      state.request_token = null;
      state.salt = null;
      break;
    case authActions.dismissMessages:
      delete state.error;
      delete state.success;
      break;
    case authActions.resetState:
      state.request_token = null;
      state.salt = null;
      delete state.error;
      delete state.success;
      break;
    default:
  }
  return state;
}

function resetPasswordPageReducer(
  prevState: ResetPasswordPageReducer = { request_token: null, salt: null },
  {
    type,
    payload,
  }: {
    type: string;
    payload: UserType | CustomError | { request_token: string; salt: string };
  }
): ResetPasswordPageReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case authActions.resetPasswordOtpWillSubmit:
      state.request_token = null;
      state.salt = null;
      delete state.error;
      state.loading = true;
      break;
    case authActions.resetPasswordOtpSucceeded:
      state.loading = false;
      state.request_token = (
        payload as { request_token: string }
      ).request_token;
      if ("salt" in payload) {
        state.salt = (payload as { salt: string }).salt;
      }
      break;
    case authActions.resetPasswordOtpFailed:
      state.loading = false;
      state.error = payload as CustomError;
      break;
    case authActions.resetPasswordWillSubmit:
      state.loading = true;
      delete state.error;
      delete state.success;
      break;
    case authActions.resetPasswordSucceeded:
      state.loading = false;
      state.request_token = null;
      state.salt = null;
      delete state.error;
      delete state.success;
      break;
    case authActions.resetPasswordFailed:
      state.loading = false;
      state.error = payload as CustomError;
      break;
    case authActions.logout:
      state.success = payload as string;
      delete state.error;
      state.request_token = null;
      break;
    case authActions.resetState:
      state.request_token = null;
      state.salt = null;
      delete state.error;
      delete state.success;
      break;
    default:
  }
  return state;
}

function updateUserProfileReducer(
  prevState: UpdateUserProfileReducer = {
    loading: false,
  },
  { type, payload }: { type: string; payload: CustomError }
): UpdateUserProfileReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case userProfileActions.updateUserProfile:
      state.loading = true;
      break;
    case userProfileActions.updateUserProfileSucceeded:
      state.loading = false;
      state.success = "User profile updated";
      delete state.error;
      break;
    case userProfileActions.updateUserProfileFailed:
      state.loading = false;
      state.error = payload as CustomError;
      delete state.success;
      break;
    case userProfileActions.dismissMessages:
      delete state.error;
      delete state.success;
      break;
    default:
  }
  return state;
}

function organizationsPageReducer(
  prevState: OrganizationsPageReducer = {
    organizations: [],
    contracts: [],
    referrals: [],
    products: [],
    packages: [],
    resellers: [],
    count: 0,
    page: 1,
    searchKeyword: "",
    loading: false,
  },
  {
    type,
    payload,
  }: {
    type: string;
    payload:
      | string
      | number
      | { list: [OrganizationType]; count: number }
      | Array<ContractType>
      | { list: [ReferralType]; count: number }
      | { list: [ProductType]; count: number }
      | { list: [PackageType]; count: number }
      | Array<ResellerType>
      | Array<CountryType>;
  }
): OrganizationsPageReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case organizationsActions.updateSearchKeyword:
      state.searchKeyword = payload as string;
      break;
    case organizationsActions.organizationsWillLoad:
      state.page = payload as number;
      state.loading = true;
      break;
    case organizationsActions.organizationsLoadSucceeded:
      state.loading = false;
      state.organizations = (payload as { list: [OrganizationType] }).list;
      state.count = (payload as { count: number }).count;
      break;

    case organizationsActions.referralsWillLoad:
      state.page = payload as number;
      state.loading = true;
      break;
    case organizationsActions.referralsLoadSucceeded:
      state.loading = false;
      state.referrals = (payload as { list: [ReferralType] }).list;
      state.count = (payload as { count: number }).count;
      break;
      case organizationsActions.productsLoadSucceeded:
        state.loading = false;
        state.products = (payload as { list: [ProductType] }).list;
        state.count = (payload as { count: number }).count;
        break;
      case organizationsActions.productsLoadFailed:
        state.loading = false;
        state.products = [];
        break;
      case organizationsActions.packagesLoadSucceeded:
          state.loading = false;
          state.packages = (payload as { list: [PackageType] }).list;
          state.count = (payload as { count: number }).count;
          break;
      case organizationsActions.packagesLoadFailed:
          state.loading = false;
          state.products = [];
          break;
    case organizationsActions.referralsLoadFailed:
        state.loading = false;
        state.organizations = [];
        break;

    case organizationsActions.contractsLoadSucceeded:
      state.contracts = payload as [ContractType];
      break;
    case organizationsActions.resellersLoadSucceeded:
      state.resellers = payload as [ResellerType];
      break;
    case organizationsActions.organizationsLoadFailed:
      state.loading = false;
      state.organizations = [];
      break;
    case organizationsActions.resetStates:
      state.loading = false;
      state.organizations = [];
      state.resellers = [];
      state.contracts = [];
      state.referrals = [];
      state.products = [];
      state.count = 0;
      state.page = 1;
      state.searchKeyword = "";
      break;
    default:
  }
  return state;
}

function updateOrganizationReducer(
  prevState: UpdateOrganizationReducer = {
    loading: false,
  },
  { type, payload }: { type: string; payload: OrganizationType | CustomError }
): UpdateOrganizationReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case organizationsActions.updateOrganization:
      state.loading = true;
      delete state.success;
      delete state.error;
      break;
    case organizationsActions.updateOrganizationSucceeded:
      state.loading = false;
      state.success = "Organization is updated";
      delete state.error;
      break;
    case organizationsActions.updateOrganizationFailed:
      state.loading = false;
      delete state.success;
      state.error = payload as CustomError;
      break;
    case organizationsActions.dismissMessages:
      delete state.error;
      delete state.success;
      break;
    default:
  }
  return state;
}

function usersPageReducer(
  prevState: UsersPageReducer = {
    users: [],
    organizations: [],
    count: 0,
    page: 1,
    loading: false,
    searchKeyword: "",
  },
  {
    type,
    payload,
  }: {
    type: string;
    payload:
      | number
      | { list: [UserType]; count: number }
      | Array<OrganizationType>
      | string
      | { organization_id: string; page: number };
  }
): UsersPageReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case usersActions.updateSearchKeyword:
      state.searchKeyword = payload as string;
      break;
    case usersActions.usersWillLoad:
      const { organization_id, page } = payload as {
        organization_id: string;
        page: number;
      };
      state.page = page;
      state.organization_id = organization_id;
      state.loading = true;
      break;
    case usersActions.usersLoadSucceeded:
      state.loading = false;
      state.users = (payload as { list: [UserType] }).list;
      state.count = (payload as { count: number }).count;
      break;
    case usersActions.usersLoadFailed:
      state.loading = false;
      state.users = [];
      break;
    case usersActions.organizationsLoadSucceeded:
      state.organizations = payload as Array<OrganizationType>;
      break;
    case usersActions.selectOrganization:
      state.organization_id = payload as string;
      break;
    case usersActions.resetStates:
      state.users = [];
      state.organizations = [];
      state.count = 0;
      state.page = 1;
      state.loading = false;
      state.searchKeyword = "";
      break;
    default:
  }
  return state;
}

function createUserReducer(
  prevState: CreateUserReducer = {
    loading: false,
    roles: [],
  },
  {
    type,
    payload,
  }: { type: string; payload: UserType | [RoleType] | CustomError }
): CreateUserReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case usersActions.createUser:
      state.loading = true;
      delete state.error;
      break;
    case usersActions.createUserSucceeded:
      state.loading = false;
      delete state.error;
      break;
    case usersActions.createUserFailed:
      state.loading = false;
      state.error = payload as CustomError;
      break;
    case usersActions.dismissUserMessages:
      delete state.error;
      break;
    case usersActions.rolesLoadSucceeded:
      state.roles = payload as [RoleType];
      break;
    case usersActions.rolesLoadFailed:
      state.roles = [];
      break;
    default:
  }
  return state;
}

function userBulkUploadReducer(
  prevState: UserBulkUploadReducer = {
    loading: false,
  },
  {
    type,
    payload,
  }: { type: string; payload: [UserBulkUploadType] | CustomError }
): UserBulkUploadReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case usersActions.userBulkUpload:
      state.loading = true;
      delete state.error;
      delete state.uploadResult;
      break;
    case usersActions.userBulkUploadSucceeded:
      state.loading = false;
      state.uploadResult = payload as [UserBulkUploadType];
      delete state.error;
      break;
    case usersActions.userBulkUploadFailed:
      state.loading = false;
      delete state.uploadResult;
      state.error = payload as CustomError;
      break;
    case usersActions.dismissSubmitUserBulkUploadMessages:
      delete state.error;
      break;
    case usersActions.resetUserBulkUploadStates:
      delete state.error;
      delete state.uploadResult;
      break;
    default:
  }
  return state;
}

function updateUserReducer(
  prevState: UpdateUserReducer = {
    loading: false,
    roles: [],
  },
  {
    type,
    payload,
  }: { type: string; payload: UserType | [RoleType] | CustomError }
): UpdateUserReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case usersActions.updateUser:
      state.loading = true;
      delete state.error;
      break;
    case usersActions.updateUserSucceeded:
      state.loading = false;
      delete state.error;
      break;
    case usersActions.updateUserFailed:
      state.loading = false;
      state.error = payload as CustomError;
      break;
    case usersActions.dismissUserMessages:
      delete state.error;
      break;
    case usersActions.rolesLoadSucceeded:
      state.roles = payload as [RoleType];
      break;
    case usersActions.rolesLoadFailed:
      state.roles = [];
      break;
    default:
  }
  return state;
}

function createRoleReducer(
  prevState: CreateRoleReducer = {
    loading: false,
  },
  { type, payload }: { type: string; payload: RoleType | CustomError }
): CreateRoleReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case rolesActions.createRole:
      state.loading = true;
      break;
    case rolesActions.createRoleSucceeded:
      state.loading = false;
      state.success = "Role is created";
      delete state.error;
      break;
    case rolesActions.createRoleFailed:
      state.loading = false;
      state.error = payload as CustomError;
      delete state.success;
      break;
    default:
  }
  return state;
}

function updateRoleReducer(
  prevState: UpdateRoleReducer = {
    loading: false,
  },
  { type, payload }: { type: string; payload: RoleType | CustomError }
): UpdateRoleReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case rolesActions.updateRole:
      state.loading = true;
      break;
    case rolesActions.updateRoleSucceeded:
      state.loading = false;
      state.success = "Role is updated";
      delete state.error;
      break;
    case rolesActions.updateRoleFailed:
      state.loading = false;
      state.error = payload as CustomError;
      delete state.success;
      break;
    default:
  }
  return state;
}

function productSubscriptionsReducer(
  prevState: ProductSubscriptionsReducer = {
    loading: false,
    count: 0,
    page: 1,
    productSubscriptions: [],
  },
  {
    type,
    payload,
  }: { type: string; payload: number | [SubscriptionType] | CustomError }
): ProductSubscriptionsReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case organizationsActions.productSubscriptionsWillLoad:
      state.loading = true;
      state.page = payload as number;
      break;
    case organizationsActions.productSubscriptionsLoadSucceeded:
      state.loading = false;
      state.productSubscriptions = (
        payload as { list: Array<SubscriptionType> }
      ).list;
      state.count = (payload as { count: number }).count;
      break;
    case organizationsActions.productSubscriptionsLoadFailed:
      state.loading = false;
      state.productSubscriptions = [];
      state.count = 0;
      break;
    default:
  }
  return state;
}

function updateProductSubscriptionReducer(
  prevState: UpdateProductSubscriptionReducer = {
    loading: false,
  },
  { type, payload }: { type: string; payload: SubscriptionType | CustomError }
): UpdateProductSubscriptionReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case organizationsActions.updateProductSubscription:
      state.loading = true;
      break;
    case organizationsActions.updateProductSubscriptionSucceeded:
      state.loading = false;
      state.success = "Product subscription updated";
      delete state.error;
      break;
    case organizationsActions.updateProductSubscriptionFailed:
      state.loading = false;
      state.error = payload as CustomError;
      delete state.success;
      break;
    case organizationsActions.dismissCreateProductSubscriptionMessages:
      delete state.error;
      delete state.success;
      break;
    default:
  }
  return state;
}

function userProductSubscriptionsReducer(
  prevState: UserProductSubscriptionsReducer = {
    loading: false,
    subscriptions: [],
  },
  {
    type,
    payload,
  }: { type: string; payload: [UserSubscriptionType] | CustomError }
): UserProductSubscriptionsReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case usersActions.userProductSubscriptionsWillLoad:
      state.loading = true;
      break;
    case usersActions.userProductSubscriptionsLoadSucceeded:
      state.loading = false;
      state.subscriptions = payload as [UserSubscriptionType];
      break;
    case usersActions.useProductSubscriptionsLoadFailed:
      state.loading = false;
      state.subscriptions = [];
      break;
    default:
  }
  return state;
}

function subscriptionsHistoryReducer(
  prevState: SubscriptionsHistoryReducer = {
    loading: false,
    count: 0,
    page: 1,
    subscriptionsHistory: [],
  },
  {
    type,
    payload,
  }: { type: string; payload: number | [SubscriptionHistoryType] | CustomError }
): SubscriptionsHistoryReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case organizationsActions.productSubscriptionsHistoryWillLoad:
      state.loading = true;
      state.page = (payload as { page: number }).page;
      break;
    case organizationsActions.productSubscriptionsHistoryLoadSucceeded:
      state.loading = false;
      state.subscriptionsHistory = (
        payload as { list: Array<SubscriptionHistoryType> }
      ).list;
      state.count = (payload as { count: number }).count;
      break;
    case organizationsActions.productSubscriptionsHistoryLoadFailed:
      state.loading = false;
      state.subscriptionsHistory = [];
      state.count = 0;
      break;
    default:
  }
  return state;
}

function rolesPageReducer(
  prevState: RolesPageReducer = {
    loading: false,
    count: 0,
    page: 1,
    roles: [],
    organizations: [],
    resellers: [],
    searchType: "all-roles",
    searchResults: [],
  },
  {
    type,
    payload,
  }: {
    type: string;
    payload:
      | { list: Array<RoleType>; count: number }
      | Array<OrganizationType>
      | Array<ResellerType>
      | CustomError
      | { list: Array<RoleType>; }
      | "all-roles" | "organization" | "role-name";
  }
): RolesPageReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case rolesActions.rolesWillLoad:
      state.loading = true;
      state.page = payload as number;
      break;
    case rolesActions.rolesLoadSucceeded:
      state.loading = false;
      state.roles = (payload as { list: Array<RoleType> }).list;
      state.count = (payload as { count: number }).count;
      break;
    case rolesActions.rolesLoadFailed:
      state.loading = false;
      state.roles = [];
      break;
    case rolesActions.roleDeleteSucceeded:
      state.success = "Role is deleted";
      break;
    case rolesActions.roleDeleteFailed:
      state.error = payload as CustomError;
      break;
    case rolesActions.dismissDeleteRoleMessages:
      delete state.error;
      delete state.success;
      break;
    case rolesActions.organizationsLoadSucceeded:
      state.organizations = payload as Array<OrganizationType>;
      break;
    case rolesActions.resellersLoadSucceeded:
      state.resellers = payload as Array<ResellerType>;
      break;
    case rolesActions.getFilteredResults:
      state.searchResults = (payload as { list: Array<RoleType> }).list;
      break;
    case rolesActions.setSearchType:
      state.searchType = payload as "all-roles" | "organization" | "role-name";
      break;
    default:
  }
  return state;
}

function rolePermissionsReducer(
  prevState: RolePermissionsReducer = {
    loading: false,
    permissions: [],
  },
  { type, payload }: { type: string; payload: [RolePermissionType] }
): RolePermissionsReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case rolesActions.permissionsWillLoad:
      state.loading = true;
      break;
    case rolesActions.permissionsLoadSucceeded:
      state.loading = false;
      state.permissions = payload as [RolePermissionType];
      break;
    case rolesActions.permissionsLoadFailed:
      state.loading = false;
      state.permissions = [];
      break;
    default:
  }
  return state;
}

function auditPageReducer(
  prevState: AuditPageReducer = {
    loading: false,
    logs: [],
    tables: [],
    selectedTable: "",
    count: 0,
    page: 1,
    organization_id: "",
    organizations: [],
    action: ''
  },
  {
    type,
    payload,
  }: {
    type: string;
    payload:
      | number
      | string
      | { list: Array<AuditLogType>; count: number }
      | [TableType]
      | Array<OrganizationType>
      | { organization_id: string; page: number };
  }
): AuditPageReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case auditActions.auditLogWillLoad:
      state.loading = true;
      state.page = payload as number;
      break;
    case auditActions.auditLogLoadSucceeded:
      const { list, count } = payload as {
        list: Array<AuditLogType>;
        count: number;
      };
      state.loading = false;
      state.logs = list;
      state.count = count;
      break;
    case auditActions.auditLogLoadFailed:
      state.loading = false;
      break;
    case auditActions.auditLogTablesLoadSucceeded:
      state.tables = payload as [TableType];
      break;
    case auditActions.auditLogTablesSelected:
      state.selectedTable = payload as string;
      break;
    case auditActions.clearSelectedTable:
      state.selectedTable = "";
      break;
    case auditActions.organizationsLoadSucceeded:
      state.organizations = payload as Array<OrganizationType>;
      break;
    case auditActions.organizationsLoadFailed:
      state.loading = false;
      state.organizations = [];
      break;
    case auditActions.selectOrganization:
      state.organization_id = payload as string;
      break;
    case auditActions.clearOrganization:
      state.organization_id = "";
      break;
    case auditActions.changeAction:
      state.action = payload as string;
      break;
    case auditActions.clearAction:
      state.action = "";
      break;
    default:
  }
  return state;
}

function licensesPageReducer(
  prevState: LicensesPageReducer = {
    loading: false,
    licenses: [],
    organizations: [],
    products: [],
    count: 0,
    page: 1,
  },
  {
    type,
    payload,
  }: {
    type: string;
    payload:
      | number
      | [OrganizationType]
      | [ProductType]
      | { count: number; list: Array<LicenseType> };
  }
): LicensesPageReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case licenseActions.organizationsLoadSucceeded:
      state.organizations = payload as Array<OrganizationType>;
      break;
    case licenseActions.productsLoadSucceeded:
      state.products = payload as Array<ProductType>;
      break;
    case licenseActions.licensesWillLoad:
      state.loading = true;
      state.page = payload as number;
      break;
    case licenseActions.licensesLoadSucceeded:
      state.loading = false;
      state.licenses = (payload as { list: Array<LicenseType> }).list;
      state.count = (payload as { count: number }).count;
      break;
    case licenseActions.licensesLoadFailed:
      state.loading = false;
      state.licenses = [];
      state.count = 0;
      break;
    case licenseActions.resetState:
      state.loading = false;
      state.licenses = [];
      state.count = 0;
      break;
    default:
  }
  return state;
}

function releasesReducer(
  prevState: ReleasesReducer = {
    loading: false,
    downloadProgress: false,
    releases: [],
    page: 1,
    count: 0,
  },
  {
    type,
    payload,
  }: {
    type: string;
    payload: { list: Array<ReleaseType>; count: number } | number;
  }
): ReleasesReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case releasesActions.releasesWillLoad:
      state.loading = true;
      state.page = payload as number;
      break;
    case releasesActions.releasesLoadSucceeded:
      state.loading = false;
      state.releases = (payload as { list: Array<ReleaseType> }).list;
      state.count = (payload as { count: number }).count;
      break;
    case releasesActions.releasesLoadFailed:
      state.loading = false;
      state.releases = [];
      break;
    case releasesActions.releasesWillDownload:
      state.downloadProgress = true;
      break;
    case releasesActions.releasesDownloadFailed:
    case releasesActions.releasesDownloadSucceeded:
      state.downloadProgress = false;
      break;
    default:
  }
  return state;
}

function invoicesReducer(
  prevState: InvoicesReducer = {
    loading: false,
    invoices: [],
    page: 1,
    count: 0,
    startDate: moment().startOf("month").subtract(1, "month").toDate(),
    endDate: moment().endOf("month").toDate(),
    keyword: "",
    status: ""
  },
  {
    type,
    payload,
  }: {
    type: string;
    payload:
      | { list: Array<InvoiceType>; count: number }
      | { startDate: Date; endDate: Date; page: number; keyword: string; status: string; };
  }
): InvoicesReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case invoicesActions.invoicesWillLoad:
      state.loading = true;
      state.page = (payload as { page: number }).page;
      state.startDate = (payload as { startDate: Date }).startDate;
      state.endDate = (payload as { endDate: Date }).endDate;
      state.keyword = (payload as { keyword: string }).keyword;
      state.status = (payload as { status: string }).status;
      break;
    case invoicesActions.invoicesLoadSucceeded:
      state.loading = false;
      state.invoices = (payload as { list: Array<InvoiceType> }).list;
      state.count = (payload as { count: number }).count;
      break;
    case invoicesActions.invoicesLoadFailed:
      state.loading = false;
      state.invoices = [];
      break;
    default:
  }
  return state;
}

function partnersPageReducer(
  prevState: PartnersPageReducer = {
    partners: [],
    count: 0,
    page: 1,
    loading: false,
  },
  {
    type,
    payload,
  }: {
    type: string;
    payload: number | { list: Array<PartnerType>; count: number };
  }
): PartnersPageReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case partnersActions.partnersWillLoad:
      state.loading = true;
      state.page = payload as number;
      break;
    case partnersActions.partnersLoadSucceeded:
      state.loading = false;
      state.partners = (payload as { list: Array<PartnerType> }).list;
      state.count = (payload as { count: number }).count;
      break;
    case partnersActions.partnersLoadFailed:
      state.loading = false;
      state.partners = [];
      break;
    default:
  }
  return state;
}

function SecurityNotificationsPageReducer(
  prevState: SecurityNotificationsPageReducer = {
    securityNotificationsList: [],
    count: 0,
    page: 1,
    loading: false,
  },
  {
    type,
    payload,
  }: {
    type: string;
    payload: number | { list: Array<SecurityNotificationsType>; count: number };
  }
): SecurityNotificationsPageReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case securityNotificationsActions.securityNotificationsWillLoad:
      state.loading = true;
      state.page = payload as number;
      break;
    case securityNotificationsActions.securityNotificationsLoadSucceeded:
      state.loading = false;
      state.securityNotificationsList = (payload as { list: Array<SecurityNotificationsType> }).list;
      state.count = (payload as { count: number }).count;
      break;
    case securityNotificationsActions.securityNotificationsLoadFailed:
      state.loading = false;
      state.securityNotificationsList = [];
      break;
    default:
  }
  return state;
}

function MaterialMasterDataReducer(
  prevState: MaterialMasterDataReducer = {
    materialMasterDataList: [],
    loading: false,
    count : 0,
  },
  {
    type,
    payload,
  }: {
    type: string;
    payload: { data: Array<MaterialMasterData>; count: number };
  }
): MaterialMasterDataReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case materialMasterDataActions.materialMasterDataWillLoad:
      state.loading = true;
      break;
    case materialMasterDataActions.materialMasterDataLoadSucceeded:
      state.loading = false;
      state.materialMasterDataList = payload.data;
      state.count = payload.count;
      break;
    case materialMasterDataActions.materialMasterDataLoadFailed:
      state.loading = false;
      state.materialMasterDataList = [];
      break;
    default:
  }
  return state;
}

function SamiReportTypeBillingReducer(
  prevState: SamiReportTypeBillingReducer = {
    reportTypeBillingList: [],
    loading: true
  },
  {
    type,
    payload,
  }: {
    type: string;
    payload: { data: Array<SamiReportTypeBillingData>; };
  }
): SamiReportTypeBillingReducer {
  let state = Object.assign({}, prevState);
  switch (type) {
    case samiReportTypeBillingActions.samiReportTypeWillLoad:
      state.loading = true;
      break;
    case samiReportTypeBillingActions.samiReportTypeLoadSucceeded:
      state.loading = false;
      state = Object.assign(state, payload.data);
      break;
    case samiReportTypeBillingActions.samiReportTypeLoadFailed:
      state.loading = false;
      state.reportTypeBillingList = [];
      break;
    default:
  }
  return state;
}

function createPartnerReducer(
  prevState: CreatePartnerReducer = {
    loading: false,
  },
  { type, payload }: { type: string; payload: PartnerType | CustomError }
): CreatePartnerReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case partnersActions.createPartner:
      state.loading = true;
      delete state.error;
      break;
    case partnersActions.createPartnerSucceeded:
      state.loading = false;
      delete state.error;
      break;
    case partnersActions.createPartnerFailed:
      state.loading = false;
      state.error = payload as CustomError;
      break;
    case partnersActions.dismissMessages:
      delete state.error;
      break;
    default:
  }
  return state;
}

function updatePartnerReducer(
  prevState: UpdatePartnerReducer = {
    loading: false,
  },
  { type, payload }: { type: string; payload: PartnerType | CustomError }
): UpdatePartnerReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case partnersActions.updatePartner:
      state.loading = true;
      delete state.error;
      break;
    case partnersActions.updatePartnerSucceeded:
      state.loading = false;
      delete state.error;
      break;
    case partnersActions.updatePartnerFailed:
      state.loading = false;
      state.error = payload as CustomError;
      break;
    case partnersActions.dismissMessages:
      delete state.error;
      break;
  }
  return state;
}

export default combineReducers<Reducers>({
  globe: globalReducer,
  loginPage: loginPageReducer,
  resetPassword: resetPasswordPageReducer,
  updateUserProfile: updateUserProfileReducer,
  organizationsPage: organizationsPageReducer,
  updateOrganization: updateOrganizationReducer,
  usersPage: usersPageReducer,
  createUser: createUserReducer,
  updateUser: updateUserReducer,
  userBulkUpload: userBulkUploadReducer,
  rolesPage: rolesPageReducer,
  rolePermissions: rolePermissionsReducer,
  createRole: createRoleReducer,
  updateRole: updateRoleReducer,
  productSubscriptions: productSubscriptionsReducer,
  updateProductSubscription: updateProductSubscriptionReducer,
  userProductSubscriptions: userProductSubscriptionsReducer,
  subscriptionsHistory: subscriptionsHistoryReducer,
  auditPage: auditPageReducer,
  releases: releasesReducer,
  licensesPage: licensesPageReducer,
  invoices: invoicesReducer,
  orgLevel: orgLevelReducers,
  autnhive: autnhiveReducers,
  reseller: resellerReducers,
  partnersPage: partnersPageReducer,
  createPartner: createPartnerReducer,
  updatePartner: updatePartnerReducer,
  securityNotificationsPartner: SecurityNotificationsPageReducer,
  materialMasterDataPartner: MaterialMasterDataReducer,
  samiReportTypeBilling: SamiReportTypeBillingReducer
});
