import { print } from "graphql";
import axios from "../axios-client";
import gql from "graphql-tag";
import { parseErrors } from "./helpers";
import { CreateSamiReportTypeBillingInput, CreateSamiReportTypeBillingResponse, GraphqlError, LicenseType, SamiReportTypeBillingResponse, SamiReportTypeBillingType, UpdateSamiReportTypeBillingInput } from "./interfaces";
import { env } from '../env';

const apiUrl: string = env.REACT_APP_COMMON_API_URL;
const URL = env.REACT_APP_SWARM_BACKEND_URL;

export async function fetchSamiReportTypeBilling(): Promise<SamiReportTypeBillingResponse> {
  const query = gql`
    query reportTypeBillingList {
      reportTypeBillingList {
          id
          report_type
          source_type
          ctem_cost
          additional_cost
          cost_ctem {
            currency
            value
          }
          cost_additional {
            currency
            value
          }
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<SamiReportTypeBillingResponse>(`${apiUrl}/graphql`, {
      query: print(query)
    },
      {
        headers: {
          "timestamp": `${timeStamp}`,
        }
      });
    return data;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function createSamiReportTypeBilling(values: CreateSamiReportTypeBillingInput): Promise<SamiReportTypeBillingType> {
  const query = gql`
    mutation createReportTypeBilling(
      $report_type: String!
      $source_type: String!
      $ctem_cost: Float!
      $cost_ctem: [PackagePriceInput!]!
      $cost_additional: [PackagePriceInput!]!
      $additional_cost: Float!
      $product_id: String
    ) {
      createReportTypeBilling(
        input: {
          report_type: $report_type
          source_type: $source_type
          cost_ctem: $cost_ctem
          ctem_cost: $ctem_cost
          additional_cost: $additional_cost
          cost_additional: $cost_additional
          product_id: $product_id
        }
      ) {
        id
        report_type
        source_type
        ctem_cost
        additional_cost
        cost_ctem {
          currency
          value
        }
        cost_additional {
          currency
          value
        }
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<CreateSamiReportTypeBillingResponse>(`${apiUrl}/graphql`, {
      query: print(query),
      variables: {
        report_type: values.report_type,
        source_type: values.source_type,
        cost_ctem: values.cost_ctem,
        ctem_cost: values.cost_ctem?.filter((c:any) => c.currency == "usd")[0].value,
        additional_cost: values.cost_additional?.filter((c:any) => c.currency == "usd")[0].value,
        cost_additional: values.cost_additional,
      },
    },
      {
        headers: {
          "timestamp": `${timeStamp}`,
        }
      });
    if (data.errors) {
      throw parseErrors(data.errors);
    }
    return data.data.createReportTypeBilling;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function updateSamiReportTypeBilling(
  values: UpdateSamiReportTypeBillingInput
) {
  const query = gql`
    mutation updateReportTypeBilling($id: ID!, $ctem_cost: Float!, $additional_cost: Float!, $cost_ctem: [PackagePriceInput!]!, $cost_additional: [PackagePriceInput!]!) {
      updateReportTypeBilling(id: $id, input: { ctem_cost: $ctem_cost, additional_cost: $additional_cost, cost_ctem: $cost_ctem, cost_additional: $cost_additional }) {
        id
        report_type
        source_type
        ctem_cost
        additional_cost
        cost_ctem {
          currency
          value
        }
        cost_additional {
          currency
          value
        }
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<{ data: SamiReportTypeBillingType }>(
      `${apiUrl}/graphql`,
      {
        query: print(query),
        variables: {
          id: values.id,
          ctem_cost: values.ctem_cost,
          additional_cost: values.additional_cost,
          cost_ctem: values.cost_ctem,
          cost_additional: values.cost_additional
        },
      },
      {
        headers: {
          "timestamp": `${timeStamp}`,
        }
      }
    );
    return data.data;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    } else {
      const graphqlError: GraphqlError = { message: err.message };
      throw parseErrors([graphqlError]);
    }
  }
}
