import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { Tooltip, Divider, IconButton } from "@mui/material";
import DollarIcon from "@mui/icons-material/MonetizationOn";
import AddLinkIcon from '@mui/icons-material/AddLink';
import OrganizationIcon from "@mui/icons-material/Business";
import ResellerIcon from "@mui/icons-material/Storefront";
import RoleIcon from "@mui/icons-material/AccountCircle";
import UsersIcon from "@mui/icons-material/People";
import StarIcon from "@mui/icons-material/StarOutline";
import UserIcon from "@mui/icons-material/Person";
import AuditIcon from "@mui/icons-material/Pageview";
import LicenseIcon from "@mui/icons-material/VpnKey";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/ExitToApp";
import ProductIcon from "@mui/icons-material/Security";
import PartnerIcon from "@mui/icons-material/Store";
import ContractIcon from "@mui/icons-material/Description";
import OnboardOrganizationIcon from "@mui/icons-material/Input";
import UploadFileSharpIcon from '@mui/icons-material/UploadFileSharp';
import BackupIcon from '@mui/icons-material/Backup';
import LanguageIcon from '@mui/icons-material/Language';

import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded';
import BillingIcon from '@mui/icons-material/DocumentScannerRounded';
import ReleaseIcon from "@mui/icons-material/DynamicFeed";
import { connect } from "react-redux";
import { GlobalReducer } from "../reducers";
import * as authActions from "../actions/auth";
import { resources } from "../permissioned";
import "./sidebarNav.scss";
interface NavLink {
  to: string;
  Icon: Function;
  resource?: string;
  title: string;
  exact?: boolean;
}
const navLinks: Array<NavLink> = [
  {
    to: "/",
    Icon: HomeIcon,
    title: "Dashboard",
    exact: true,
  },
  {
    to: "/admin/autnhive/contracts",
    Icon: ContractIcon,
    resource: resources.AutnhiveContracts,
    title: "Contracts",
  },
  {
    to: "/admin/autnhive/organizations-onboardings",
    Icon: OnboardOrganizationIcon,
    resource: resources.OrganizationsOnboardings,
    title: "Organization Onboarding",
  },
  {
    to: "/admin/organizations",
    Icon: OrganizationIcon,
    resource: resources.Organizations,
    title: "Organizations",
  },
  {
    to: "/admin/autnhive/resellers",
    Icon: ResellerIcon,
    resource: resources.Resellers,
    title: "Resellers",
  },
  {
    to: "/admin/partners",
    Icon: PartnerIcon,
    resource: resources.Partners,
    title: "Partners",
  },
  {
    to: "/admin/users",
    Icon: UsersIcon,
    resource: resources.Users,
    title: "Users",
  },
  {
    to: "/admin/autnhive/users",
    Icon: StarIcon,
    resource: resources.AutnhiveUsers,
    title: "AutnHive Users",
  },
  {
    to: "/admin/roles",
    Icon: RoleIcon,
    resource: resources.Roles,
    title: "Roles",
  },
  {
    to: "/admin/licenses",
    Icon: LicenseIcon,
    resource: resources.Licenses,
    title: "Licenses",
  },
  /*
  disabled for now until we make a call to decide whether or not to keep this
  {
    to: "/admin/autnhive/releases",
    Icon: ReleaseIcon,
    resource: resources.Releases,
    title: "Releases",
  },
  */
  {
    to: "/admin/audit",
    Icon: AuditIcon,
    resource: resources.Audit,
    title: "Audit",
  },
 
  {
    to: "/admin/invoices",
    Icon: DollarIcon,
    resource: resources.Invoices,
    title: "Invoices",
  },
  {
    to: "/admin/vendor",
    Icon: AddLinkIcon,
    resource: resources.Invoices,
    title: "Vendor",
  },
  {
    to: "/admin/securityNotifications",
    Icon: UploadFileSharpIcon,
    resource: resources.Invoices,
    title: "Security Notifications",
  },
  {
    to: "/admin/materialMasterDataUpload",
    Icon: BackupIcon,
    resource: resources.Invoices,
    title: "Material Risks",
  },
  // {
  //   to: "/admin/sami",
  //   Icon: ChatBubbleOutlineRoundedIcon,
  //   resource: resources.AskSami,
  //   title: "Ask SAMI",
  // },
 
];

interface SidebarNavProps {
  globe: GlobalReducer;
  logout: Function;
}

function renderLink(
  props: SidebarNavProps,
  link: NavLink,
  index: number
) {
  let active = false;
  const location = useLocation();
  if (link.exact) {
    active = link.to === location?.pathname;
  } else if (location?.pathname.indexOf(link.to) > -1) {
    active = true;
  }
  const className = active ? "active-navitem" : "";
  return (
    <div key={index}>
      <Link to={link.to}>
        <Tooltip title={link.title} placement="right">
          <IconButton color="secondary" className={className} size="large">
            <link.Icon />
          </IconButton>
        </Tooltip>
      </Link>
    </div>
  );
}

export function SidebarNav(props: SidebarNavProps) {
  const location = useLocation();
  const {
    globe: { userProfile },
  } = props;
  const links: Array<NavLink> = navLinks.filter((link: NavLink) => {
    if (!link.resource) return true;
    return userProfile?.permissions.find((p) => p.resource === link.resource);
  });

  return (
    <div className="sidebar-nav">
      {links.map((link, index) => renderLink(props, link, index))}
    
      <Link to={"/admin/easa"}>
        <Tooltip title={"EASA"}  placement="right">
          <IconButton className={"/admin/easa" === location?.pathname ? "active-navitem" : ""} color="secondary"  size="large">
            <LanguageIcon />
          </IconButton>
        </Tooltip>
      </Link>
      <Link to={"/admin/sami"}>
        <Tooltip title={"Ask SAMI"}  placement="right">
          <IconButton className={"/admin/sami" === location?.pathname ? "active-navitem" : ""} color="secondary"  size="large">
          
            <ChatBubbleOutlineRoundedIcon/>
          </IconButton>
        </Tooltip>
      </Link>
      <Link to={"/admin/samiReportTypeBilling"}>
        <Tooltip title={"SAMI Report Types"}  placement="right">
          <IconButton className={"/admin/samiReportTypeBilling" === location?.pathname ? "active-navitem" : ""} color="secondary"  size="large">
          
            <BillingIcon/>
          </IconButton>
        </Tooltip>
      </Link>
      {userProfile?.user.role.role_for !== "autnhive" ? (
        <Link to="/admin/o/product-subscriptions">
          <Tooltip title="Subscriptions" placement="right">
            <IconButton
              color="secondary"
              className={
                location?.pathname === "/admin/o/product-subscriptions"
                  ? "active-navitem"
                  : ""
              }
              size="large">
              <ProductIcon />
            </IconButton>
          </Tooltip>
        </Link>
      ) : null}
      <div className="spacer" />
      <Divider />
      <Link to="/my-profile">
        <Tooltip title="My Profile" placement="right">
          <IconButton
            color="secondary"
            className={
              location?.pathname === "/my-profile" ? "active-navitem" : ""
            }
            size="large">
            <UserIcon />
          </IconButton>
        </Tooltip>
      </Link>
      <div>
        <Tooltip title="Logout" placement="right">
          <IconButton
            color="secondary"
            onClick={() => props.logout("You have logged out")}
            size="large">
            <LogoutIcon />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
}

const mapStateToProps = ({ globe }: { globe: GlobalReducer }) => ({ globe });
const mapDispatchToProps = (dispatch: any): { logout: Function } => {
  return {
    logout: (message: string) => dispatch(authActions.logout(message)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarNav);
