import { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  Button,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import { useSnackbar } from "notistack";
import { CreateSamiReportTypeBillingInput, UpdateSamiReportTypeBillingInput } from "../services/interfaces";
import { createSamiReportTypeBilling, fetchSamiReportTypeBilling, updateSamiReportTypeBilling } from "../actions/samiReportTypeBilling";

const LIMIT = 20;

export default function SamiReportTypeBilling() {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const [reportType, setReportType] = useState<string>("");
  const [sourceType, setSourceType] = useState<string>("cloud");
  const [ctemCost, setCtemCost] = useState<number | "">("");
  const [ctemCostUSD, setCtemCostUSD] = useState<number | "">("");
  const [ctemCostCAD, setCtemCostCAD] = useState<number | "">("");
  const [ctemCostGBP, setCtemCostGBP] = useState<number | "">("");
  const [additionalCost, setAdditionalCost] = useState<number | "">("");
  const [additionalCostUSD, setAdditionalCostUSD] = useState<number | "">("");
  const [additionalCostCAD, setAdditionalCostCAD] = useState<number | "">("");
  const [additionalCostGBP, setAdditionalCostGBP] = useState<number | "">("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const samiReportTypeBillingReducer = useSelector((state: any) => state.samiReportTypeBilling);
  const { enqueueSnackbar } = useSnackbar();
  const [isEditing, setIsEditing] = useState(false);
  const [selectedBillId, setSelectedBillId] = useState<string>("null");
  const [searchTerm, setSearchTerm] = useState("");
  const filteredBillingList = samiReportTypeBillingReducer?.reportTypeBillingList?.filter(
    (samiReportTypeBilling: any) => {
      const searchValue = searchTerm.toLowerCase();
      return (
        samiReportTypeBilling.report_type.toLowerCase().includes(searchValue) ||
        samiReportTypeBilling.source_type.toLowerCase().includes(searchValue)
      );
    }
  );

  useEffect(() => {
    dispatch(fetchSamiReportTypeBilling());
  }, [dispatch]);

  const handleSubmit = async () => {
    setIsSubmitted(true);
    if (!reportType || !sourceType || !ctemCostUSD || !ctemCostCAD || !ctemCostGBP || 
      !additionalCostUSD || !additionalCostCAD || !additionalCostGBP
    ) {
      enqueueSnackbar("Please fill out all required fields.", {
        variant: "error",
      });
      return;
    }

    const values: CreateSamiReportTypeBillingInput = {
      report_type: reportType,
      source_type: sourceType,
      cost_ctem: [{currency: 'usd', value: ctemCostUSD}, {currency:'cad', value: ctemCostCAD}, {currency:'gbp', value: ctemCostGBP}],
      cost_additional: [{currency: 'usd', value: additionalCostUSD}, {currency:'cad', value: additionalCostCAD}, {currency:'gbp', value: additionalCostGBP}]
    };

    try {
      if (isEditing) {
        const values: UpdateSamiReportTypeBillingInput = {
          id: selectedBillId,
          ctem_cost: ctemCost as number,
          additional_cost: additionalCost as number,
          cost_ctem: [{currency: 'usd', value: ctemCostUSD}, {currency:'cad', value: ctemCostCAD}, {currency:'gbp', value: ctemCostGBP}],
          cost_additional: [{currency: 'usd', value: additionalCostUSD}, {currency:'cad', value: additionalCostCAD}, {currency:'gbp', value: additionalCostGBP}]
        };
        await dispatch(updateSamiReportTypeBilling(values));
        enqueueSnackbar("Report Type updated successfully!", {
          variant: "success",
        });
      } else {
        await dispatch(createSamiReportTypeBilling(values));
        enqueueSnackbar("Report Type created successfully!", {
          variant: "success",
        });
      }

      setShowDialog(false);
      dispatch(fetchSamiReportTypeBilling());
    } catch (error) {
      enqueueSnackbar("Failed to create Report Type. Please try again.", {
        variant: "error",
      });
    }
  };

  return (
    <div>
      <Typography component="h1" variant="h5">
        SAMI Report Types
      </Typography>

      <div className="mb-2">
        <Grid container spacing={2}>
          {/* <Grid item>
            <TextField
              size="small"
              label="Search Report Type"
              variant="outlined"
              placeholder="Press Enter to search"
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyPress={(e) => {
                if (e.key !== "Enter") {
                  return;
                }
              }}
            />
          </Grid> */}

          <Grid item marginLeft="auto" marginRight="20px">
            <Button
              color="secondary"
              size="small"
              variant="outlined"
              onClick={() => {
                setShowDialog(true);
                setIsSubmitted(false);
                setReportType("");
                setSourceType("cloud");
                setCtemCost("");
                setCtemCostUSD("");
                setCtemCostCAD("");
                setCtemCostGBP("");
                setAdditionalCost("");
                setAdditionalCostUSD("");
                setAdditionalCostCAD("");
                setAdditionalCostGBP("");
                setIsEditing(false);
              }}
              style={{ marginBottom: "16px" }}
            >
              New Report Type
            </Button>
          </Grid>
        </Grid>
        <div
          className="app-container domains-table"
          style={{
            display: "flex",
            padding: "1rem",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <table className="domains-table">
            <thead>
              <tr>
                <th>Report Type</th>
                <th>Source type</th>
                <th>CTEM Scan Cost (USD)</th>
                <th>Additional Scan Cost (USD)</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {samiReportTypeBillingReducer?.loading ? (
                <tr>
                  <td colSpan={7} style={{ textAlign: "center" }}>
                    <CircularProgress size={26} color="inherit" />
                  </td>
                </tr>
              ) : (
                filteredBillingList?.map((reportTypeBilling: any, index: number) => (
                  <tr key={index}>
                    <td>{reportTypeBilling.report_type}</td>
                    <td>{reportTypeBilling.source_type}</td>
                    <td>{reportTypeBilling.cost_ctem?.filter((c:any) => c.currency == 'usd')[0].value}</td>
                    <td>{reportTypeBilling.cost_additional?.filter((c:any) => c.currency == 'usd')[0].value}</td>
                    <td>
                      <IconButton
                        onClick={() => {
                          setShowDialog(true);
                          setIsSubmitted(false);
                          setSelectedBillId(reportTypeBilling.id);
                          setReportType(reportTypeBilling.report_type);
                          setSourceType(reportTypeBilling.source_type.toLowerCase());
                          setCtemCost(reportTypeBilling.ctem_cost);
                          setCtemCostUSD(reportTypeBilling.cost_ctem?.filter((c:any) => c.currency == 'usd')[0].value)
                          setCtemCostCAD(reportTypeBilling.cost_ctem?.filter((c:any) => c.currency == 'cad')[0].value)
                          setCtemCostGBP(reportTypeBilling.cost_ctem?.filter((c:any) => c.currency == 'gbp')[0].value)
                          setAdditionalCost(reportTypeBilling.additional_cost);
                          setAdditionalCostUSD(reportTypeBilling.cost_additional?.filter((c:any) => c.currency == 'usd')[0].value)
                          setAdditionalCostCAD(reportTypeBilling.cost_additional?.filter((c:any) => c.currency == 'cad')[0].value)
                          setAdditionalCostGBP(reportTypeBilling.cost_additional?.filter((c:any) => c.currency == 'gbp')[0].value)
                          setIsEditing(true);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </td>
                  </tr>
                ))
              )}
              {!filteredBillingList?.length && !samiReportTypeBillingReducer?.loading && (
                <tr>
                  <td colSpan={7} style={{ textAlign: "center" }}>
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Dialog
          fullWidth
          maxWidth='md'
          open={showDialog}
          onClose={() => {
            setShowDialog(false);
            setIsEditing(false);
            setSelectedBillId("");
          }}
        >
          <DialogTitle>SAMI Report Type</DialogTitle>
          <DialogContent>
            <FormControl fullWidth margin="normal">
              <TextField
                variant="standard"
                label="Report Type"
                value={reportType}
                onChange={(e) => setReportType(e.target.value)}
                error={isSubmitted && !reportType}
                helperText={
                  isSubmitted && !reportType ? "Report Type is required" : ""
                }
                disabled={isEditing}
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel variant="standard">Source Type</InputLabel>
              <Select
                variant="standard"
                value={sourceType}
                onChange={(e) => setSourceType(e.target.value)}
                error={isSubmitted && !sourceType}
                disabled={isEditing}
              >
                <MenuItem value="cloud">Cloud</MenuItem>
                <MenuItem value="code">Code</MenuItem>
                <MenuItem value="network">Network</MenuItem>
                <MenuItem value="endpoint">Endpoint</MenuItem>
              </Select>
            </FormControl>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <FormControl  margin="normal">
                <TextField
                  variant="standard"
                  label="CTEM Cost (USD)"
                  value={ctemCostUSD}
                  type="number"
                  onChange={(e) => {
                    const value =
                      e.target.value === "" ? "" : parseFloat(e.target.value);
                    if (value === "" || (!isNaN(value) && value >= 0)) {
                      setCtemCostUSD(value);
                    }
                  }}
                  error={isSubmitted && !ctemCostUSD}
                  helperText={
                    isSubmitted && !ctemCostUSD ? "CTEM Cost (USD) is required" : ""
                  }
                />
              </FormControl>
              </Grid>
              <Grid item>
                <FormControl margin="normal">
                <TextField
                  variant="standard"
                  label="CTEM Cost (CAD)"
                  value={ctemCostCAD}
                  type="number"
                  onChange={(e) => {
                    const value =
                      e.target.value === "" ? "" : parseFloat(e.target.value);
                    if (value === "" || (!isNaN(value) && value >= 0)) {
                      setCtemCostCAD(value);
                    }
                  }}
                  error={isSubmitted && !ctemCostCAD}
                  helperText={
                    isSubmitted && !ctemCostCAD ? "CTEM Cost (CAD) is required" : ""
                  }
                />
              </FormControl>
              </Grid>
              <Grid item>
                <FormControl margin="normal">
                <TextField
                  variant="standard"
                  label="CTEM Cost (GBP)"
                  value={ctemCostGBP}
                  type="number"
                  onChange={(e) => {
                    const value =
                      e.target.value === "" ? "" : parseFloat(e.target.value);
                    if (value === "" || (!isNaN(value) && value >= 0)) {
                      setCtemCostGBP(value);
                    }
                  }}
                  error={isSubmitted && !ctemCostGBP}
                  helperText={
                    isSubmitted && !ctemCostGBP ? "CTEM Cost (GBP) is required" : ""
                  }
                />
              </FormControl>
              </Grid>
            </Grid>
            
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <FormControl fullWidth margin="normal">
                <TextField
                  variant="standard"
                  label="Additional Cost (USD)"
                  value={additionalCostUSD}
                  type="number"
                  onChange={(e) => {
                    const value =
                      e.target.value === "" ? "" : parseFloat(e.target.value);
                    if (value === "" || (!isNaN(value) && value >= 0)) {
                      setAdditionalCostUSD(value);
                    }
                  }}
                  error={isSubmitted && !additionalCostUSD}
                  helperText={
                    isSubmitted && !additionalCostUSD
                      ? "Additional Cost (USD) is required"
                      : ""
                  }
                />
              </FormControl>
              </Grid>
              <Grid item>
                <FormControl fullWidth margin="normal">
                <TextField
                  variant="standard"
                  label="Additional Cost (CAD)"
                  value={additionalCostCAD}
                  type="number"
                  onChange={(e) => {
                    const value =
                      e.target.value === "" ? "" : parseFloat(e.target.value);
                    if (value === "" || (!isNaN(value) && value >= 0)) {
                      setAdditionalCostCAD(value);
                    }
                  }}
                  error={isSubmitted && !additionalCostCAD}
                  helperText={
                    isSubmitted && !additionalCostCAD
                      ? "Additional Cost (CAD) is required"
                      : ""
                  }
                />
              </FormControl>
              </Grid>
              <Grid item>
                <FormControl fullWidth margin="normal">
                <TextField
                  variant="standard"
                  label="Additional Cost (GBP)"
                  value={additionalCostGBP}
                  type="number"
                  onChange={(e) => {
                    const value =
                      e.target.value === "" ? "" : parseFloat(e.target.value);
                    if (value === "" || (!isNaN(value) && value >= 0)) {
                      setAdditionalCostGBP(value);
                    }
                  }}
                  error={isSubmitted && !additionalCostGBP}
                  helperText={
                    isSubmitted && !additionalCostGBP
                      ? "Additional Cost (GBP) is required"
                      : ""
                  }
                />
              </FormControl>
              </Grid>
            </Grid>
            
            <div
              style={{
                marginTop: "16px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit}
              >
                Submit
              </Button>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => setShowDialog(false)}
              >
                Cancel
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}
