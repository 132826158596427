import { CreateSamiReportTypeBillingInput, UpdateSamiReportTypeBillingInput } from "../services/interfaces";
import * as samiReportTypeBillingService from "../services/samiReportTypeBilling";

export const samiReportTypeBillingActions: any = {
  samiReportTypeWillLoad: "SAMI_REPORT_LOG_WILL_LOAD",
  samiReportTypeLoadSucceeded: "SAMI_REPORT_LOG_LOAD_SUCCEEDED",
  samiReportTypeLoadFailed: "SAMI_REPORT_LOG_LOAD_FAILED",
  updateSamiReportTypeWillLoad: "UPDATE_SAMI_REPORT_LOG_WILL_LOAD",
  updateSamiReportTypeLoadSucceeded: "UPDATE_SAMI_REPORT_LOG_LOAD_SUCCEEDED",
  updateSamiReportTypeLoadFailed: "UPDATE_SAMI_REPORT_LOG_LOAD_FAILED",
};

export const createSamiReportTypeBilling =
  (values: CreateSamiReportTypeBillingInput) => async (dispatch: any) => {
    try {
      dispatch({
        type: samiReportTypeBillingActions.samiReportTypeLoadSucceeded,
        payload: await samiReportTypeBillingService.createSamiReportTypeBilling(values),
      });
    } catch (e) {
      dispatch({
        type: samiReportTypeBillingActions.samiReportTypeLoadFailed,
        payload: e,
      });
      throw e;
    }
  };

export const updateSamiReportTypeBilling =
  (values: UpdateSamiReportTypeBillingInput) => async (dispatch: any) => {
    try {
      dispatch({
        type: samiReportTypeBillingActions.updateSamiReportTypeLoadSucceeded,
        payload: await samiReportTypeBillingService.updateSamiReportTypeBilling(values),
      });
    } catch (e) {
      dispatch({
        type: samiReportTypeBillingActions.updateSamiReportTypeLoadFailed,
        payload: e,
      });
      throw e;
    }
  };

export const fetchSamiReportTypeBilling =
  () => async (dispatch: any) => {
    try {
      dispatch({
        type: samiReportTypeBillingActions.samiReportTypeLoadSucceeded,
        payload: await samiReportTypeBillingService.fetchSamiReportTypeBilling(),
      });
    } catch (err) {
      dispatch({
        type: samiReportTypeBillingActions.samiReportTypeLoadFailed,
        payload: err,
      });
    }
  };

