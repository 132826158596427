import React, { useEffect } from "react";
import moment from "moment";
import { Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dialog, DialogTitle, DialogContent } from "@mui/material";
import SidebarNav from "./components/sidebarNav";
import Login from "./pages/login";
import Dashboard from "./pages/dashboard";
import Users from "./pages/users";
import AutnhiveUsers from "./pages/autnhive/users";
import Roles from "./pages/roles";
import Organizations from "./pages/organizations";
import OrganizationsOnboardings from "./pages/autnhive/organizations-onboardings";
import OrgLevelProducts from "./pages/orgLevel/products";
import Audit from "./pages/audit";
import Licenses from "./pages/licenses";
import Sami from "./pages/sami";
import Easa from "./pages/easa";
import Jobs from "./pages/jobs";
import Tasks from "./pages/tasks";
import SubTasks from "./pages/subTasks";
import UserProfile from "./pages/userProfile";
import { GlobalReducer, Reducers } from "./reducers";
import * as authActions from "./actions/auth";
import { SnackbarProvider } from "notistack";
import Releases from "./pages/autnhive/releases";
import Resellers from "./pages/autnhive/resellers";
import Contracts from "./pages/autnhive/contracts";
import Partners from "./pages/partners";
import Invoices from "./pages/invoices";
import axiosInstance from "./axios-client";
import { DATETIME_FORMAT } from "./constants";
import "./App.scss";
import Vendor from "./pages/vendor";
import SecurityNotifications from "./pages/securityNotifications";
import MaterialMasterDataUpload from "./pages/materialMasterDataUpload";
import SamiReportTypeBilling from "./pages/samiReportTypeBilling";

function LoginPage() {
  const globe = useSelector<Reducers, GlobalReducer>((state) => state.globe);
  const dispatch = useDispatch();

  useEffect(() => {
    axiosInstance.interceptors.response.use(
      (config) => config,
      (err) => {
        if (
          ["/auth/reset-password", "/auth/login"].indexOf(
            new URL(err.request.responseURL).pathname
          ) > -1
        ) {
          return Promise.reject(err);
        }
        if (err && err.response.status === 401) {
          dispatch(authActions.logout());
        }
        return Promise.reject(err);
      }
    );
  }, [dispatch]);

  if(globe.loggedIn){
    return <Navigate to="/" />
  }
  return <Login />
}

function AppRoutes(props: any) {
  const globe = useSelector<Reducers, GlobalReducer>((state) => state.globe);
  const dispatch = useDispatch();

  useEffect(() => {
    axiosInstance.interceptors.response.use(
      (config) => config,
      (err) => {
        if (
          ["/auth/reset-password", "/auth/login"].indexOf(
            new URL(err.request.responseURL).pathname
          ) > -1
        ) {
          return Promise.reject(err);
        }
        if (err && err.response.status === 401) {
          dispatch(authActions.logout());
        }
        return Promise.reject(err);
      }
    );
  }, [dispatch]);

  if (!globe.loggedIn) {
    return <Navigate to="/login" />;
  }
  const expired: boolean = moment().isAfter(
    moment(globe?.userProfile?.session_expire_time, DATETIME_FORMAT)
  );
  if (expired) {
    return (
      <Dialog disableEscapeKeyDown open={true}>
        <DialogTitle>Session expired</DialogTitle>
        <DialogContent>
          <div>Your session has expired. Please login again</div>
          <br />
          <Button
            variant="contained"
            color="primary"
            onClick={() => dispatch(authActions.logout())}
          >
            Login
          </Button>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <div className="app">
      <div className="sidebar">
        <SidebarNav />
      </div>
      <div className="main-content">
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/my-profile" element={<UserProfile {...props} />} />
          <Route path="/admin/users/*" element={<Users />} />
          <Route path="/admin/roles/*" element={<Roles />} />
          <Route path="/admin/organizations/*" element={<Organizations />} />
          <Route path="/admin/audit" element={<Audit />} />
          <Route path="/admin/licenses" element={<Licenses />} />
          <Route path="/admin/invoices/*" element={<Invoices />} />
          <Route path="/admin/vendor/*" element={<Vendor />} />
          <Route path="/admin/securityNotifications/*" element={<SecurityNotifications />} />
          <Route path="/admin/materialMasterDataUpload/*" element={<MaterialMasterDataUpload />} />
          <Route path="/admin/sami/*" element={<Sami />} />
          <Route path="/admin/easa" element={<Easa />} />
          <Route path="/admin/samiReportTypeBilling" element={<SamiReportTypeBilling />} />
          <Route path="/admin/easa/job-details" element={<Jobs />} />
          <Route path="/admin/easa/job-details/tasks" element={<Tasks />} />
          <Route path="/admin/easa/job-details/tasks/sub-tasks" element={<SubTasks />} />
          <Route path="/admin/autnhive/users/*" element={<AutnhiveUsers />} />
          <Route path="/admin/autnhive/releases/*" element={<Releases />} />
          <Route path="/admin/autnhive/resellers/*" element={<Resellers />} />
          <Route path="/admin/autnhive/contracts/*" element={<Contracts />} />
          <Route path="/admin/partners/*" element={<Partners {...props}/>} />
          <Route path="/admin/autnhive/organizations-onboardings/*" element={<OrganizationsOnboardings />} />
          <Route path="/admin/o/product-subscriptions" />
        </Routes>
      </div>
    </div>
  );
}

export default function App() {
  const dispatch = useDispatch();
  const globe = useSelector<Reducers, GlobalReducer>((state) => state.globe);

  useEffect(() => {
    dispatch(authActions.initialize());
  }, [dispatch]);

  if (globe.sessionLoading) {
    return <div>Loading...</div>;
  }
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
    >
      <Routes>
        <Route path="/login/*" element={<LoginPage />}/>
        <Route path="/*" element={<AppRoutes />} />
      </Routes>
    </SnackbarProvider>
  );
}
